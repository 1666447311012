import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";

import { useSelector } from "react-redux";
import { IAppState } from '../../store';
import styles from './index.module.css';
import Image from './Image'
import { Storage } from "aws-amplify";
import Slider, { Settings } from "react-slick";
import { Skeleton } from "@material-ui/lab";

interface IProps {

}

const GalleryModule: React.FC<IProps> = (props) => {
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const [images, setImages] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const settings: Settings = {
    slidesToShow: 3,
    swipeToSlide: false,
    dots: true,
    rows: 3,
    slidesPerRow: 1,
    arrows: true,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          swipeToSlide: true,
          dots: true,
          rows: 1,
          slidesPerRow: 1,
          arrows: false,
          centerPadding: "0px",
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  useEffect(() => {
    if (website.gallery.length > 0) {
      getCarouselImages(website.gallery)
    }
  }, website.gallery)

  const getCarouselImages = async (images: string[]) => {
    const config = { contentType: "image/*", level: "public" };
    const imageUrls = await Promise.all(images.map(async (image: string) => {
      const url = Storage.get(image, config).then((result) => {
        setLoading(true)
        return result;
      })
      return url
    }))
    if (imageUrls.length > 0) {
      setLoading(false)
      setImages(imageUrls as string[]);
    }
  }

  return (
    <div className={styles.carouselModule}>
      <Container>
        <div className="flex col-flex cross-center ">
          <div className={styles.sectionHeaderLine}></div>
          <h4>Vehicle Gallery</h4>
        </div>
        <div className={styles.carauselContainer}>
          {!loading ? (
            <Slider className={"slickySlider"} {...settings} >
              {
                images.map((image, index) => <Image key={index} imageKey={image} />)
              }
            </Slider>
          ) : <Skeleton variant="rect" height={400} />
          }
        </div>
      </Container>
    </div>
  )
}

export default GalleryModule