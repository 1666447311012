import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styles from "../index.module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Storage } from "aws-amplify";
import { useSelector } from 'react-redux';
import { DateTime as d } from "luxon";
import InfoIcon from '@material-ui/icons/Info';
import { IAppState } from '../../../../../store';
import { DATE_TYPE, getAddonIcon, getFeatureTypeIcon, getRetnalTaxAmount, getTaxDescription, getVehicleTypeIcon, isLongTermBooking, toCurrency } from '../../../../utils';
import { SimpleDateTimePicker } from '../../../../SimpleDateTimePicker/intex';
import { NuvvenDivider } from '../../../Summary/Components/Divider';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, Grid, IconButton, InputLabel, ListSubheader, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import { BILLING_CYCLE_NAME, IAddonRequirementInput, IAddons, IBookingCreateInput, ISubscription, ISubscriptionArgs, ISubscriptionInfo, ISubscriptionPricing, ISuggestedPricing, IVehicle } from '../../../../../reducers/bookings/types';
import { useLazyQuery } from '@apollo/client';
import { GET_AVAILABLE_ADDON_TYPES } from '../../../../../graphql/addOns/getAvailableAddOnsQuery';
import { capitalizeFirstLetter, RATE_TYPES } from '../../../utils';
import FlatPickerBar from '../../../../FlatPicker';
import { getLocalizedDateFormat, getLocalizedTaxSyntex } from '../../../../../utils/localized.syntex';
import { AddonsSelection } from '../SelectAddOns/AddonsSelection';
import { DefaultAddons, IAddonRate, IAddonType } from '../SelectAddOns/types';

enum RentalTimeText {
  "hourly" = "hour",
  "daily" = "day",
  "weekly" = "week",
  "monthly" = "month"
}

interface IProps {
  bookingData: IBookingCreateInput;
  setBookingData: Dispatch<SetStateAction<IBookingCreateInput>>;
  onSubmit: (data: IBookingCreateInput) => void;
  selectedVehicle?: IVehicle;
  onSelectAvailabilityDate: (date: string) => void;
  onSelectSubscription: (selectedSubscription: ISubscriptionArgs, vehicle: IVehicle, startDate: string) => void
}

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1500,
  autoplaySpeed: 3000,
  cssEase: "linear"
};
const VehicleDetail: React.FC<IProps> = (props) => {

  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const branches = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.branches);
  const authState = useSelector((state: IAppState) => state.authReducer);
  const authUser = useSelector((state: IAppState) => state.authReducer.user);
  const { locale, currency } = organisation;
  const { country } = website.organisation.address;
  const branch = branches.find((branch) => branch.id === props.bookingData.branchId);
  const [selectedPlan, setSelectedPlan] = useState<ISubscriptionInfo>()
  const [selectedPricing, setSelectedPricing] = useState<ISubscriptionPricing>()
  const [startDate, setStartDate] = useState<string>('')

  const [bookingDurationInDays, setBookingDurationInDays] = useState<any>();

  const taxes = branch && branch.addTaxOptIn ? branch.taxes.map((tax) => {
    return {
      code: tax.title,
      type: tax.type,
      rate: tax.rate,
      amount: tax.value,
      isVehicleGroupSpecific: tax.isVehicleGroupSpecific || false,
      vehicleGroups: tax.vehicleGroups || [],
      subdivisionOptIn: tax.subdivisionOptIn,
      subdivisionRate: tax.subdivisionRate,
      subdivisionType: tax.subdivisionType,
      subdivisionValue: tax.subdivisionValue
    }
  }) : [];

  let totalSubscriptionAmount = props.bookingData?.insuranceRate + 0;
  totalSubscriptionAmount += (props.bookingData.activeSubscription?.subscriptionAmount || 0) + (props.bookingData.activeSubscription?.enrollmentAmount || 0) + getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, props.bookingData.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1)

  const [loadAddons, { loading: addonsLoading, data: addonsData }] = useLazyQuery(GET_AVAILABLE_ADDON_TYPES, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (props.bookingData.branchId) {
      loadAddons({
        variables: {
          branchId: props.bookingData.branchId,
          startDate: props.bookingData.pickupDateTime,
          endDate: props.bookingData.dropoffDateTime,
          bookingId: props.bookingData.id || "",
          rateTypeName: props.bookingData.rateTypeName,
          businessCustomer: props.bookingData.businessCustomer ? props.bookingData.businessCustomer : authState.user?.businesscustomers?.id
        }
      });
    }
  }, []);

  useEffect(() => {
    if (props.bookingData) {
      let bookingDuration;
      const longTermBooking = isLongTermBooking(props.bookingData);
      if (longTermBooking) {
        switch (props.bookingData.rateTypeName) {
          case BILLING_CYCLE_NAME.WEEKLY:
            if (props.bookingData.isRecurringBilling && props.bookingData.billingFrequency) {
              bookingDuration = 7 * props.bookingData.billingFrequency
            } else {
              bookingDuration = 7
            }
            setBookingDurationInDays(bookingDuration);
            break;
          case BILLING_CYCLE_NAME.MONTHLY:
            if (props.bookingData.isRecurringBilling && props.bookingData.billingFrequency) {
              let idealEndDate = d.fromISO(props.bookingData.pickupDateTime || "").plus({ months: props.bookingData.billingFrequency }).toUTC().toISO();
              bookingDuration = d.fromISO(idealEndDate).diff(d.fromISO(props.bookingData.pickupDateTime), "day").days;
              setBookingDurationInDays(bookingDuration);
            }
            break;
          default:
            break;
        }
      } else {
        bookingDuration = Math.ceil(d.fromISO(props.bookingData.dropoffDateTime || "").diff(d.fromISO(props.bookingData.pickupDateTime || ""), ["days"]).days);
        setBookingDurationInDays(bookingDuration);
      }
    }
  }, [props.bookingData]);

  useEffect(() => {
    let monthlyPricing: ISuggestedPricing = { subscriptionAmount: Infinity, rateTypeName: 'monthly', subscription: "", duration: 0 };
    let weeklyPricing: ISuggestedPricing = { subscriptionAmount: Infinity, rateTypeName: 'weekly', subscription: "", duration: 0 };

    props.selectedVehicle?.subscriptions.forEach(subscription => {
      subscription.pricings.forEach(pricing => {
        if (subscription.subscription.rateTypeName === 'monthly') {
          if (pricing.subscriptionAmount < monthlyPricing.subscriptionAmount) {
            monthlyPricing = {
              subscriptionAmount: pricing.subscriptionAmount,
              rateTypeName: 'monthly',
              subscription: subscription.subscription.id || "",
              duration: pricing.duration
            };
          }
        } else if (subscription.subscription.rateTypeName === 'weekly') {
          if (pricing.subscriptionAmount < weeklyPricing.subscriptionAmount) {
            weeklyPricing = {
              subscriptionAmount: pricing.subscriptionAmount,
              rateTypeName: 'weekly',
              subscription: subscription.subscription.id || "",
              duration: pricing.duration
            };
          }
        }
      });
    });

    const pricing = monthlyPricing.subscriptionAmount < Infinity ? monthlyPricing : weeklyPricing;
    const subscription = props.selectedVehicle?.subscriptions.find(sub => sub.subscription.id === pricing.subscription)
    const selectedPricing = subscription?.pricings.find(p => p.duration === pricing.duration)
    if (props.bookingData.activeSubscription) {
      const selectedSubscription = props.selectedVehicle?.subscriptions.find(sub => sub.subscription.id === props.bookingData.activeSubscription.subscription)
      const selectedPricing = selectedSubscription?.pricings.find(pricing => pricing.duration === props.bookingData.activeSubscription.duration)
      setSelectedPlan(selectedSubscription)
      setSelectedPricing(selectedPricing)
    } else {
      setSelectedPlan(subscription)
      setSelectedPricing(selectedPricing)
    }
    setStartDate(props.selectedVehicle?.availableFrom || "")
    if (subscription && pricing) {
      props.onSelectSubscription(
        {
          pricing: selectedPricing,
          subscription: subscription.subscription as any,
          deposit: subscription?.deposit
        },
        props.selectedVehicle as any,
        props.selectedVehicle?.availableFrom || "");
    }
  }, [props.selectedVehicle])

  const getAddonRate = (addon: IAddonRequirementInput, rentalTime: number) => {
    let rate = addon.rate * addon.quantity;
    if (!addon.hasFixedRate && rentalTime) {
      rate = rate * rentalTime;
    }
    return rate;
  };

  return (
    <Grid container item xs={12} spacing={2} >
      <Grid item xs={8}>
        {/* <Grid item xs={12}>
          <Typography style={{ fontSize: 32, textTransform: "capitalize" }} variant='h2'>{`${props.selectedVehicle?.make} ${props.selectedVehicle?.model}`}</Typography>
          <Typography variant={"h3"} style={{ fontSize: 14, color: "#000", marginTop: 8 }}>Available From: {getLocalizedDateFormat(country, props.selectedVehicle?.availableFrom || "", DATE_TYPE.CONDENSED)}</Typography>
          {selectedPlan?.subscription.insurancePolicy && (
            <Typography variant='h3' color='secondary' style={{ fontSize: 14 }}>Included {selectedPlan.subscription.insurancePolicy.name} Insurance</Typography>
          )}
        </Grid> */}
        <Box mt={2}></Box>
        <Grid item xs={12}>
          <div className='carauselContainer' style={{ borderRadius: "0px" }} >
            <Slider className="slickySlider" {...settings}>
              <div className='imageContainer' onClick={() => { }}>
                <img
                  className={"image"}
                  alt="loading.."
                  src={props.selectedVehicle?.imageUrl || `${getVehicleTypeIcon(capitalizeFirstLetter(props.selectedVehicle?.bodyType || ""))}`}
                />
              </div>
              <div className='imageContainer' onClick={() => { }}>
                <img
                  className={"image"}
                  alt="loading.."
                  src={props.selectedVehicle?.imageUrl || `${getVehicleTypeIcon(capitalizeFirstLetter(props.selectedVehicle?.bodyType || ""))}`}
                />
              </div>
            </Slider>
          </div>
        </Grid>
        <Box mt={8}></Box>
        <Grid item xs={12}><Box mt={5}></Box></Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}><Typography variant='h4' style={{ fontSize: 20 }}>Vehicle Specification</Typography></Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={4}>
              <Typography variant='h3' style={{ fontSize: 14, color: "#747474" }}>Model</Typography>
              <Box mt={0.5}></Box>
              <Typography variant='h4'>{props.selectedVehicle?.model}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h3' style={{ fontSize: 14, color: "#747474" }}>Fuel Type</Typography>
              <Box mt={0.5}></Box>
              <Typography variant='h4'>{props.selectedVehicle?.fuelType}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h3' style={{ fontSize: 14, color: "#747474" }}>Color</Typography>
              <Box mt={0.5}></Box>
              <Typography variant='h4'>{props.selectedVehicle?.colour}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h3' style={{ fontSize: 14, color: "#747474" }}>Body Type</Typography>
              <Box mt={0.5}></Box>
              <Typography variant='h4'>{props.selectedVehicle?.bodyType}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h3' style={{ fontSize: 14, color: "#747474" }}>Tank Capacity</Typography>
              <Box mt={0.5}></Box>
              <Typography variant='h4'>{props.selectedVehicle?.tankCapacity || "N/A"}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h3' style={{ fontSize: 14, color: "#747474" }}>Interior Color</Typography>
              <Box mt={0.5}></Box>
              <Typography variant='h4'>{props.selectedVehicle?.interiorColour || "N/A"}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h3' style={{ fontSize: 14, color: "#747474" }}>Seats</Typography>
              <Box mt={0.5}></Box>
              <Typography variant='h4'>{props.selectedVehicle?.numberOfSeats || "N/A"}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h3' style={{ fontSize: 14, color: "#747474" }}>Door's</Typography>
              <Box mt={0.5}></Box>
              <Typography variant='h4'>{props.selectedVehicle?.numberOfDoors || "N/A"}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}><Box mt={1}></Box></Grid>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              <Box >
                <Typography variant='h4' style={{ fontSize: 20 }}>What's included</Typography>
                <ul className={styles.plan_inclusion_list}>
                  <li>Your chosen car</li>
                  <li>Insurance</li>
                  <li>All servicing and maintenance</li>
                  <li>Road tax</li>
                  <li>Breakdown cover</li>
                  <li>100 miles/ month</li>
                  <li>24/7 helpline</li>
                  <li>{selectedPricing?.swapsAllowed} time vehicle swap</li>
                  {
                    selectedPlan &&
                    selectedPlan.subscription.addons?.length &&
                    selectedPlan.subscription.addons.map(addon => {
                      return <li>{addon.displayName}</li>
                    })
                  }
                </ul>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box >
                <Typography variant='h4' style={{ fontSize: 20 }}>Contractual details</Typography>
                <ul className={styles.plan_inclusion_list}>
                  <li>The longer you subscribe for, the more you can save</li>
                  <li>Tailor your subscription to your specific needs</li>
                  <li>Manage seamlessly and easily online</li>
                  <li>Swap your car if you want or cancel at any time</li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}><Box mt={3}></Box></Grid>

        <Grid item xs={12}><Box mt={3}></Box></Grid>
        {/* {
          props.bookingData.pickupDateTime && props.bookingData.dropoffDateTime && (
            <Grid item xs={12}>
              <AddonsSelection
                onPrevious={() => { }}
                onSubmit={() => { }}
                oneWayRentalFee={undefined}
                setBookingData={props.setBookingData}
                bookingData={props.bookingData}
              />
            </Grid>
          )
        } */}
      </Grid>
      <Grid item xs={4} spacing={2}>
        <Grid item xs={12}>
          <Box style={{ padding: 20 }}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}><Typography style={{ fontWeight: 400, fontSize: 24 }} variant='h4'>Customize your contract</Typography></Grid>
            </Grid>
            <Box mt={2.5}></Box>
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-contract-plan-label">
                <Typography style={{ fontSize: 16, fontWeight: 400, }} variant='h3'>Select Plan</Typography>
              </InputLabel>
              <Box mt={0.5}></Box>
              <FormControl fullWidth variant='outlined'>
                <Select
                  labelId="demo-simple-select-contract-plan-label"
                  id="demo-simple-select-contract-plan-label-select"
                  variant='outlined'
                  className={styles.subscription_input_style}
                  value={selectedPlan?.subscription.id || ""}
                  onChange={(event: any) => {
                    const plan = props.selectedVehicle?.subscriptions.find(sub => sub.subscription.id === event.target.value)
                    if (plan) {
                      setSelectedPricing(undefined)
                      setSelectedPlan(plan)
                    }
                  }}
                >
                  {
                    props.selectedVehicle?.subscriptions.map((subscription, index) => (
                      <MenuItem
                        value={subscription.subscription.id}
                        key={subscription.subscription.id}
                      >
                        {subscription.subscription.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Box mt={2}></Box>
              <InputLabel id="demo-simple-select-contract-plan-label">
                <Typography style={{ fontSize: 16, fontWeight: 400 }} variant='h3'>Select Tenure</Typography>
              </InputLabel>
              <Box mt={0.5}></Box>
              <FormControl fullWidth variant='outlined'>
                <Select
                  labelId="demo-simple-select-contract-period-label"
                  id="demo-simple-select-contract-plan-period-select"
                  value={selectedPricing?.duration || ""}
                  className={styles.subscription_input_style}
                  onChange={(event: any) => {
                    const pricing = selectedPlan?.pricings.find(pricing => pricing.duration === event.target.value)
                    if (pricing && selectedPlan) {
                      setSelectedPricing(pricing)
                      props.onSelectSubscription(
                        {
                          pricing,
                          subscription: selectedPlan.subscription as any,
                          deposit: selectedPlan.deposit
                        },
                        props.selectedVehicle as any,
                        props.bookingData.pickupDateTime || startDate
                      );
                    }
                  }}

                >
                  {
                    selectedPlan?.pricings.map((pricing, index) => (
                      <MenuItem
                        value={pricing.duration}
                        key={index}
                      >
                        {`${pricing.duration} ${selectedPlan.subscription.rateTypeName === "monthly" ? "Month's" : "Week's"} (${toCurrency(pricing.subscriptionAmount, currency, locale)}/${selectedPlan.subscription.rateTypeName === "monthly" ? "Month" : "Week"})`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Box mt={2}></Box>
              <InputLabel id="demo-simple-select-contract-plan-label">
                <Typography style={{ fontSize: 16, fontWeight: 400 }} variant='h3'>Select Mileage</Typography>
              </InputLabel>
              <Box mt={0.5}></Box>
              <FormControl fullWidth variant='outlined'>
                <Select
                  labelId="demo-simple-select-contract-mileage-label"
                  id="demo-simple-select-contract-plan-mileage-select"
                  value={selectedPricing?.duration || ""}
                  className={styles.subscription_input_style}
                  onChange={(event: any) => {
                    const pricing = selectedPlan?.pricings.find(pricing => pricing.duration === event.target.value)
                    if (pricing && selectedPlan) {
                      setSelectedPricing(pricing)
                      props.onSelectSubscription(
                        {
                          pricing,
                          subscription: selectedPlan.subscription as any,
                          deposit: selectedPlan.deposit
                        },
                        props.selectedVehicle as any,
                        props.bookingData.pickupDateTime || startDate
                      );
                    }
                  }}

                >
                  {/* {
                    selectedPlan?.pricings.map((pricing, index) => (
                      <MenuItem
                        value={pricing.duration}
                        key={index}
                      >
                        {`${pricing.duration} ${selectedPlan.subscription.rateTypeName === "monthly" ? "Month's" : "Week's"} (${toCurrency(pricing.subscriptionAmount, currency, locale)}/${selectedPlan.subscription.rateTypeName === "monthly" ? "Month" : "Week"})`}
                      </MenuItem>
                    ))} */}
                </Select>
              </FormControl>
            </Grid>
            <Box mt={2}></Box>
            <Grid item xs={12}>
              <InputLabel>
                <Typography variant='h3' style={{ fontSize: 16, fontWeight: 400 }}>Tentative start date</Typography>
              </InputLabel>
              <Box mt={0.5}></Box>
              <Grid item xs={12}>
                <FlatPickerBar
                  handleDateChange={(value: Date) => {
                    if (value) {
                      if (selectedPricing && selectedPlan) {
                        setStartDate(d.fromJSDate(value).toUTC().toISO())
                        props.onSelectSubscription(
                          {
                            pricing: selectedPricing,
                            subscription: selectedPlan.subscription as any,
                            deposit: selectedPlan.deposit
                          },
                          props.selectedVehicle as any,
                          d.fromJSDate(value).toUTC().toISO()
                        );
                      }
                    }
                  }}
                  identifier={"availabilityDate"}
                  name={"subscriptionDate"}
                  placeholderValue={"Select Date.."}
                  value={getLocalizedDateFormat(country, startDate, DATE_TYPE.CONDENSED)}
                  minDate={getLocalizedDateFormat(country, d.fromISO(props.selectedVehicle?.availableFrom || "").startOf('day').toUTC().toISO(), DATE_TYPE.CONDENSED)}
                  enableTime={false}
                  type={"availability-time-picker"}
                  country={country}
                />
              </Grid>
            </Grid>
            <Box mt={3}></Box>
            {
              selectedPlan && selectedPricing && (
                <>
                  <Grid item xs={12}><Typography style={{ fontWeight: 400, fontSize: 24 }} variant='h4'>Summary of your contract</Typography></Grid>
                  <Box mt={3}></Box>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={5} container>
                      <Typography style={{ fontSize: 16 }} variant={"h5"}>{"Charges"}</Typography>
                    </Grid>
                    <Grid item xs={7} container justify={"flex-end"} spacing={1}>
                      <Grid item xs={12} container justify={"flex-end"}><Typography variant={"h4"}>{toCurrency(selectedPricing.subscriptionAmount + (getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, props.bookingData?.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1)), currency, locale)}</Typography></Grid>
                      <Grid item xs={12} container justify={"flex-end"}>
                        <Typography variant={"caption"}>{props.bookingData.activeSubscription.rateTypeName} {toCurrency(selectedPricing.subscriptionAmount, currency, locale)}</Typography>
                      </Grid>
                      <Grid item xs={12} container justify={"flex-end"}>
                        <Typography variant={"caption"}>{"Enrollment Fee"} {toCurrency(selectedPricing.enrollmentAmount, currency, locale)}</Typography>
                      </Grid>
                      <Grid item xs={12} container justify={"flex-end"}>
                        <Typography variant='caption'>
                          <IconButton
                            style={{
                              padding: 0,
                              paddingRight: 10
                            }}
                          >
                            <Tooltip
                              title={
                                <React.Fragment>
                                  {
                                    getTaxDescription(taxes, currency, locale).length > 0 &&
                                    getTaxDescription(taxes, currency, locale).map(title => {
                                      return (
                                        <>
                                          <span>({title})</span><br></br>
                                        </>
                                      )
                                    })
                                  }
                                </React.Fragment>
                              }
                              arrow
                              placement={"top"}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </IconButton>
                          {getLocalizedTaxSyntex(country || "")} : {toCurrency(getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, props.bookingData?.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1), currency, locale)}
                        </Typography>
                      </Grid>
                    </Grid>
                    {
                      props.bookingData.addonRequirements.length > 0 && (
                        <Grid item xs={12} container>
                          <Typography style={{ fontSize: 16 }} variant={"h5"}>Add-ons</Typography>
                        </Grid>
                      )
                    }
                    {
                      props.bookingData.addonRequirements.map((addon, index) => {
                        let taxAmount = 0;
                        if (!authUser?.customer?.taxExempted) {
                          if (addon.hasFixedRate) {
                            if (addon.tax && addon.tax.value) {
                              taxAmount = ((addon.rate * addon.tax.value / 100) * addon.quantity);
                            }
                          } else {
                            if (addon.tax && addon.tax.value) {
                              taxAmount = ((addon.rate * addon.tax.value / 100) * addon.quantity * 1);
                            }
                          }
                        }
                        totalSubscriptionAmount += getAddonRate(addon, 1) + taxAmount
                        return (
                          <Grid container item xs={12} spacing={1}>
                            <Grid container item xs={6}>
                              <Typography variant='h4'>{addon.displayName ? addon.displayName : addon.name}</Typography>
                            </Grid>
                            <Grid item xs={6} container justify={"flex-end"} spacing={1}>
                              <Typography variant="h4">{toCurrency(getAddonRate(addon, 1) + taxAmount, currency, locale)}</Typography>
                            </Grid>
                            <Grid item xs={6} container>
                              <Typography variant='caption'>Quantity: {addon.quantity}</Typography>
                            </Grid>
                            <Grid item xs={6} container justify='flex-end'>
                              <Typography variant="caption">{addon.quantity} x {addon.hasFixedRate ? toCurrency(addon.rate, currency, locale) : `(${1} ${RentalTimeText[props.bookingData.rateTypeName as RATE_TYPES]}${1 > 1 ? "s" : ""} x ${toCurrency(addon.rate, currency, locale)})`}</Typography>
                            </Grid>
                            <Grid item xs={12} container justify='flex-end' alignItems='center'>
                              {
                                addon.tax && addon.tax.title && addon.tax.value && (
                                  <IconButton
                                    style={{
                                      padding: 0,
                                      paddingRight: 10
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        <>
                                          <Typography>({`${addon.tax.title} ${addon.tax.value}%`})</Typography>
                                        </>
                                      }
                                      arrow
                                      placement={"right"}
                                    >
                                      <InfoIcon />
                                    </Tooltip>
                                  </IconButton>
                                )
                              }
                              {getLocalizedTaxSyntex(country || "")}  : {toCurrency(taxAmount, currency, locale)}
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                    {
                      props.bookingData.insurancePolicy && (
                        <>
                          <Grid item xs={12} container>
                            <Typography style={{ fontSize: 16 }} variant={"h5"}>Insurance</Typography>
                          </Grid>
                          <Grid container item xs={12} spacing={1}>
                            <Grid container item xs={6}>
                              <Typography variant='h4'>{props.bookingData.insuranceName}</Typography>
                            </Grid>
                            <Grid item xs={6} container justify={"flex-end"} spacing={1}>
                              <Typography variant="h4">{toCurrency(props.bookingData.insuranceRate * 1 * (1), currency, locale)}</Typography>
                            </Grid>
                          </Grid>
                        </>
                      )
                    }
                    <NuvvenDivider noMargin />
                    <Grid item xs={12} container spacing={1}>
                      <Grid item xs={6} container>
                        <Typography style={{ fontSize: 16 }} variant={"h5"}>Total</Typography>
                      </Grid>
                      <Grid item xs={6} container justify={"flex-end"}>
                        <Typography variant={"h4"}>{toCurrency(totalSubscriptionAmount, currency, locale)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box mt={2.5}></Box>
                  <Grid item xs={12}>
                    <Button
                      className={styles.next_step_btn}
                      onClick={() => props.onSubmit(props.bookingData)}
                      fullWidth
                    >
                      Continue To Personal Details
                    </Button>
                  </Grid>
                </>
              )
            }
          </Box>
        </Grid>
      </Grid >
    </Grid >
  )
}

export default VehicleDetail