import { ITestDriveBookingState } from "../../reducers/testDriveBooking/types";

export const CLEAR_TEST_DRIVE_BOOKING = "CLEAR_TEST_DRIVE_BOOKING";
export const UPDATE_TEST_DRIVE_BOOKING = "UPDATE_TEST_DRIVE_BOOKING";

interface IClearTestDriveBooking {
  type: typeof CLEAR_TEST_DRIVE_BOOKING;
}

interface IUpdateTestDriveBooking {
  payload: ITestDriveBookingState;
  type: typeof UPDATE_TEST_DRIVE_BOOKING;
}

export type TestDriveBookingAction =
  | IClearTestDriveBooking
  | IUpdateTestDriveBooking;
