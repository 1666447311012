import { gql } from "apollo-boost";

export const GET_SPECIFIC_BRANCHES = gql`
  query getSpecificBranches($appSettingId: String!, $branchIds: [String]!) {
    getSpecificBranches(appSettingId: $appSettingId, branchIds: $branchIds) {
      id
      name
      address {
        fullAddress
      }
      phoneNumber {
        phone
      }
      emailId
      locations {
        id
        name
        branch {
          name
          id
        }
        address {
          fullAddress
          zipcode
          geometry {
            type
            latitude
            longitude
          }
        }
      }
    }
  }
`;
