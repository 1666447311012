import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { TestDriveBookingSteps } from "../../../../reducers/testDriveBooking/types";
import { updateTestDriveBooking } from "../../../../actions/testDriveBooking/actions";
import { MouseEventHandler, useEffect, useState } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { GET_SPECIFIC_BRANCHES } from "../../../../graphql/bookings/getSpecificBranches";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../utils";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { Skeleton } from "@material-ui/lab";
import GoogleMapReact from "google-map-react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxHeight: 240,
    borderRadius: 0,
  },
  content: {
    position: "relative",
    zIndex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  branchContainer: {
    overflowY: "scroll",
    maxHeight: "700px",
  },
}));

interface IBranchSummary {
  id: string;
  name: string;
  address: {
    fullAddress: string;
  };
  phoneNumber: {
    phone: string;
  };
  geometery: {
    latitude: number;
    longitude: number;
    type: string;
  };
  emailId: string;
  vehicleGroupId: string;
  locations: string[];
}

interface MapProps {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
}

interface ImageCardProps {
  branchDetails: IBranchSummary;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const initialBranchSummary: IBranchSummary = {
  id: "",
  name: "",
  address: { fullAddress: "" },
  phoneNumber: { phone: "" },
  emailId: "",
  vehicleGroupId: "",
  locations: [],
  geometery: {
    latitude: 0,
    longitude: 0,
    type: "",
  },
};

export default function BranchCard({
  branchDetails,
  onClick = () => {},
}: ImageCardProps) {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.content}>
        <Typography variant="h2" component="h2" gutterBottom>
          {branchDetails.name}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {branchDetails.address.fullAddress}
        </Typography>
        {branchDetails.phoneNumber && (
          <Typography variant="body1" gutterBottom>
            {branchDetails.phoneNumber.phone}
          </Typography>
        )}
        {branchDetails.emailId && (
          <Typography variant="body1" gutterBottom>
            {branchDetails.emailId}
          </Typography>
        )}
        <Divider style={{ margin: "1rem 0 0.5rem 0" }} />
        <Grid
          item
          container
          xs={12}
          direction="column"
          alignContent="space-between"
        >
          <Grid item xs={12}>
            <Button startIcon={<ArrowForwardIcon />} onClick={onClick}>
              Continue
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export const BranchSelection: React.FC = () => {
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const classes = useStyles();

  const [branchDetails, setBranchDetails] = useState<IBranchSummary[]>([
    initialBranchSummary,
  ]);
  const [mapProps, setMapProps] = useState<MapProps>({
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  });

  const testDriveBookingState = useSelector(
    (state: IAppState) => state.testDriveBookingReducer
  );

  const appSetting = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );

  const [getBranchSummary, { loading: branchLoading }] = useLazyQuery(
    GET_SPECIFIC_BRANCHES,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.getSpecificBranches) {
          setBranchDetails(data.getSpecificBranches);
          setMapProps({
            ...mapProps,
            center: {
              lat: data.getSpecificBranches[0].locations[0].address.geometry
                .latitude,
              lng: data.getSpecificBranches[0].locations[0].address.geometry
                .longitude,
            },
          });
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR,
        });
      },
    }
  );

  useEffect(() => {
    if (testDriveBookingState.availableBranches) {
      const branchIds = testDriveBookingState.availableBranches.map(
        (data) => data.branchId
      );
      getBranchSummary({
        variables: {
          appSettingId: appSetting.id,
          branchIds,
        },
      });
    }
  }, [appSetting, testDriveBookingState, getBranchSummary]);

  const nextStep = (
    branchName: string,
    branchId: string,
    location: string,
    vehicleGroupId: string
  ) => {
    dispatch(
      updateTestDriveBooking({
        ...testDriveBookingState,
        branchId,
        vehicleGroupId,
        stepperData: {
          ...testDriveBookingState.stepperData,
          slot: "",
          experience: "",
          model: "",
          dealership: branchName,
        },
        pickupLocation: location,
        currentStep: TestDriveBookingSteps.VEHICLE_SELECTION,
        vehicleId: "",
      })
    );
  };

  const prevStep = () => {
    dispatch(
      updateTestDriveBooking({
        ...testDriveBookingState,
        currentStep: TestDriveBookingSteps.WELCOME_PAGE,
      })
    );
  };

  return (
    <Grid container xs={12} sm={12} md={12} spacing={2}>
      <Grid
        item
        container
        xs={12}
        spacing={1}
        alignItems="flex-start"
        justify="flex-start"
      >
        <Grid item>
          <IconButton aria-label="previous-step" onClick={prevStep}>
            <ArrowBackIcon style={{ padding: 0 }} />
          </IconButton>
        </Grid>
        <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
          <Typography variant="h1" component={"h1"}>
            Select A Dealership
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={12} md={12} spacing={2}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          spacing={1}
          direction="row"
          alignContent="flex-start"
          alignItems="flex-start"
          className={classes.branchContainer}
        >
          {branchLoading &&
            new Array(3).fill(1).map(() => (
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rect" height={210} />
              </Grid>
            ))}
          {!branchLoading &&
            branchDetails.map((branch) => (
              <Grid item xs={12}>
                <BranchCard
                  onClick={() => {
                    const vehicleGroupId =
                      testDriveBookingState.availableBranches.filter(
                        (data) => data.branchId === branch.id
                      )[0].vehicleGroupId;
                    nextStep(
                      branch.name,
                      branch.id,
                      branch.locations[0],
                      vehicleGroupId
                    );
                  }}
                  branchDetails={branch}
                />
              </Grid>
            ))}
        </Grid>
        <Grid container item xs={12} sm={12} md={8} xl={8} lg={8}>
          <div style={{ height: "700px", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              center={mapProps.center}
              defaultZoom={mapProps.zoom}
            ></GoogleMapReact>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
