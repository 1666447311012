import { useLazyQuery } from "@apollo/client";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { GET_INSURANCE_POLICIES } from "../../../../graphql/insurancePolicy/getInsurancePolicies";
import {
  DefaultAddons,
  IAddonRate,
  IAddonType,
  ValetType
} from "./types";
import {
  IAddonRequirementInput,
  IAddressLocation,
  IBookingCreateInput,
  IInsuranceAddon,
  IOneWayRentalFee,
} from "../../../../reducers/bookings/types";
import { CustomCard } from "./CustomCard";
import { InsuranceCard } from "./InsuranceCard";
import styles from './index.module.css';
import Grid from '@material-ui/core/Grid';
import { Container, Hidden, Typography } from '@material-ui/core';
import { GET_AVAILABLE_ADDON_TYPES } from "../../../../graphql/addOns/getAvailableAddOnsQuery";
import { SummaryPanel } from '../../../SummaryPanel';
import { ValetCard } from "./ValetCard";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";

interface IAddonsProps {
  onPrevious(): void;
  values: IBookingCreateInput;
  setValues: Dispatch<SetStateAction<IBookingCreateInput>>
  onSubmit(data?: IBookingCreateInput): void;
  updateParentState(newValue: string): void;
  oneWayRentalFee: IOneWayRentalFee | undefined
}

export const AddonsSelection: React.FC<IAddonsProps> = (props) => {
  const [activatedAddons, setActivatedAddons] = useState<IAddonType[]>([]);
  const [selectedAddons, setSelectedAddons] = useState<
    IAddonRequirementInput[]
  >([]);
  const [insuranceAddOns, setInsuranceAddOns] = useState<IInsuranceAddon[]>([]);
  const authState = useSelector((state: IAppState) => state.authReducer);
  const { hideInsurance } = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { values, setValues } = props;

  const [loadAddons, { loading: addonsLoading, data: addonsData }] = useLazyQuery(GET_AVAILABLE_ADDON_TYPES, {
    fetchPolicy: "network-only"
  });

  const [loadInsurancePolicies, { loading: insuranceLoading, data: insurancePolicyData }] = useLazyQuery(
    GET_INSURANCE_POLICIES,
    {
      fetchPolicy: "network-only"
    }
  );
  
  useEffect(() => {
    if (values.branchId) {
      loadInsurancePolicies();
      loadAddons({
        variables: {
          branchId: values.branchId,
          startDate: values.pickupDateTime,
          endDate: values.dropoffDateTime,
          bookingId: values.id || "",
          rateTypeName: values.rateTypeName,
          businessCustomer: values.businessCustomer ? values.businessCustomer : authState.user?.businesscustomers?.id
        }
      });
    }
  }, []);

  // add on data
  useEffect(() => {
    if (addonsData && addonsData.consumerAddonTypes) {
      const selectAddons: IAddonRequirementInput[] = [];
      const applicableAddons = addonsData.consumerAddonTypes.filter(
        (addon: IAddonType) => {
          const addonPresent = values.addonRequirements.find(
            (addReq) => addReq.addon === addon.id
          );
          if (addon.isActivated && addonPresent) {
            selectAddons.push(addonPresent);
          }
          return addon.isActivated;
        }
      );
      if (applicableAddons.length) {
        applicableAddons.sort((a: any, b: any) => {
          const addon1 = a.name || "";
          const addon2 = b.name || "";
          return addon1.toLowerCase() < addon2.toLowerCase()
        })      
        const valetIndex = applicableAddons.findIndex((addon : any) => addon.name === DefaultAddons.VALET);
        // If a 'VALET' object exists, move it to the 0 index      
        if (valetIndex && valetIndex !== 0) {
          const valetAddon = applicableAddons.splice(valetIndex, 1)[0];
          applicableAddons.unshift(valetAddon);
        }        
        setActivatedAddons(applicableAddons);
        setSelectedAddons(selectAddons);
      }
    }
  }, [addonsData, values.addonRequirements]);

  // insurance policy data
  useEffect(() => {
    if (insurancePolicyData && insurancePolicyData.consumerInsurancePolicies) {
      const insuranceAddOns = [];
      for (
        let index = 0;
        index < insurancePolicyData.consumerInsurancePolicies.length;
        index++
      ) {
        const insurancePolicy = insurancePolicyData.consumerInsurancePolicies[index];
        const insuranceRate = insurancePolicy.insuranceRate.find(
          (iRate: any) => iRate.rateName === values.rateTypeName
        );
        if (insuranceRate) {
          insuranceAddOns.push({
            ...insuranceRate,
            policyId: insurancePolicy.id,
            excess: insurancePolicy.excess,
            policyName: insurancePolicy.name,
            description: insurancePolicy.description
          });
        }
      }
      setInsuranceAddOns(insuranceAddOns);
    }
  }, [insurancePolicyData]);

  const getAddons = (
    addonType?: IAddonType,
    rate?: number,
    quantity?: number,
    valetType?: string
  ) => {
    if (addonType && addonType.id && quantity) {
      const addons = [...selectedAddons];
      const existingIndex = selectedAddons.findIndex(
        (addon) => addon.addon === addonType.id
      );
      if (existingIndex > -1) {
        addons[existingIndex] = {
          ...addons[existingIndex],
          quantity,
          rate: rate || 0,
        };
      } else {
        // add to selected addons
        if(!valetType || valetType.length == 0) {
          addons.push({
            addon: addonType.id,
            hasFixedRate: addonType.hasFixedRate,
            name: addonType.name,
            quantity,
            displayName: addonType.displayName,
            rate: rate || 0,
            taxable: addonType.taxable,
            tax: addonType.taxable && addonType.tax ? {
              title: addonType.tax.title,
              value: addonType.tax.value
            } : {
              title: "",
              value: 0
            }
          });
        }
        else {
          props.updateParentState(valetType ? valetType : "");
          addons.push({
          displayName: valetType,
          addon: addonType.id,
          hasFixedRate: addonType.hasFixedRate,
          name: addonType.name,
          quantity,
          rate: rate || 0,
          taxable: addonType.taxable,
          tax: addonType.taxable && addonType.tax ? {
            title: addonType.tax.title,
            value: addonType.tax.value
          } : {
            title: "",
            value: 0
          }
        });
        }
      }
      return addons;
    } else {
      return selectedAddons.filter(
        (addon) => addon.addon !== addonType?.id
      );
    }
  };

  const onCustomAddonSelect = (
    addonType?: IAddonType,
    rate?: number,
    quantity?: number,
    additionalDrivers?: number
  ) => {    
    const addons = getAddons(addonType, rate, quantity);
    setSelectedAddons(addons);
    setValues({
      ...values,
      addonRequirements: addons
    });
  };

  const onInsuranceAddonSelect = (insuranceAddOn?: string) => {
    if (insuranceAddOn) {
      const selectedInsurance = insuranceAddOns.find(
        (addOn) => addOn.policyId === insuranceAddOn
      );
      if (selectedInsurance) {
        setValues({
          ...values,
          ...{
            insuranceRate: selectedInsurance.rate,
            excess: selectedInsurance.excess,
            insurancePolicy: selectedInsurance.policyId,
            insuranceName: selectedInsurance.policyName
          }
        });
      }
    } else {
      setValues({
        ...values,
        ...{
          insuranceRate: 0,
          excess: 0,
          insurancePolicy: undefined,
          insuranceName: undefined
        }
      });
    }
  };

  const onCoiSelect = (flag: boolean) => {
    if (flag) {
      setValues({
        ...values, 
        isCoi : true,
        insurancePolicy: "",
        insuranceRate: 0
      })
    } else {
      setValues({
        ...values, 
        isCoi : false
      })
    }
  }

  const onValetSelect = (args: {
    addonType: IAddonType,
    quantity: number,
    rate?: number,
    pickupOtherLocation?: IAddressLocation,
    dropoffOtherLocation?: IAddressLocation
  }) => {
    const { addonType, rate, pickupOtherLocation, dropoffOtherLocation } = args;  
    if (addonType &&
      (pickupOtherLocation?.fullAddress ||
        dropoffOtherLocation?.fullAddress)
    ) {
      const addons = getAddons(addonType, rate, 1);
      setSelectedAddons(addons);
      setValues({
        ...values,
        addonRequirements: addons,
        pickupOtherLocation,
        dropoffOtherLocation
      });
    } else {
      const addons = getAddons(addonType);
      const { pickupOtherLocation, dropoffOtherLocation, ...rest } = values;
      setSelectedAddons(addons);
      setValues({
        ...rest,
        addonRequirements: addons
      });
    }
  };
  

  if (addonsLoading || insuranceLoading) {
    return <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </div>
  }

  return (
    <Container>
      <Grid container spacing={0}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" align="center" style={{ fontWeight: 700, marginBottom: 20 }} >Select Add-Ons</Typography>
          {
            !insuranceAddOns.length && !activatedAddons.length && (
              <Grid container item xs={12}>
                <Typography variant="h4">No available add-ons found matching your search criteria</Typography>
              </Grid>
            )
          }          
          {
            <Grid container spacing={1} style={{ paddingBottom: 100 }}>
              {!hideInsurance && insuranceAddOns.length > 0 && (
                <Grid item xs={12} md={6}>
                  <div
                    className={styles.insuranceAddonCard}
                    style={values.insurancePolicy ? {
                      borderColor: "var(--primary-color)",
                      backgroundColor: 'var(--accent-shade-light)'
                    } : {}}
                  >
                    <InsuranceCard
                      insuranceAddOns={insuranceAddOns}
                      onSelect={onInsuranceAddonSelect}
                      selectedAddon={values.insurancePolicy}
                      onCoiSelect={onCoiSelect}
                      isCoi={values.isCoi}
                    />
                  </div>
                </Grid>
              )}
              {activatedAddons.map((addonType: IAddonType, index: number) => {                
                const selectedAddon = selectedAddons.find(
                  (ao) => ao.addon === addonType.id
                );                             
                if (addonType.name === DefaultAddons.VALET) {
                  return (
                    <Grid item xs={12} md={6} key={index}>
                        <ValetCard 
                          addon={addonType}                                                    
                          bookingData={values}
                          onSelect={onValetSelect}
                          selectedAddon={selectedAddon}     
                        />                      
                    </Grid>
                  );                  
                }
                else if(addonType.name === DefaultAddons.ADDITIONAL_DRIVER) {
                  return (
                    <Grid item xs={12} sm={6} md={4}>                      
                      <div
                        className={styles.customAddonCard}
                        style={addonType.name === selectedAddon?.name ? {
                          borderColor: "var(--primary-color)",
                          backgroundColor: 'var(--accent-shade-light)'
                        } : {}}
                      >
                        <CustomCard
                          addon={addonType}
                          rateTypeName={props.values.rateTypeName}
                          onSelect={onCustomAddonSelect}
                          selectedAddon={selectedAddon}
                        />
                      </div>
                    </Grid>
                  )
                }
                else {
                  if (addonType.availableQuantity) {
                    return (
                      <Grid item xs={12} sm={6} md={4}>
                        <div
                          className={styles.customAddonCard}
                          style={addonType.name === selectedAddon?.name ? {
                            borderColor: "var(--primary-color)",
                            backgroundColor: 'var(--accent-shade-light)'
                          } : {}}
                        >
                          <CustomCard
                            addon={addonType}
                            rateTypeName={props.values.rateTypeName}
                            onSelect={onCustomAddonSelect}
                            selectedAddon={selectedAddon}
                          />
                        </div>
                      </Grid>
                    );
                  }
                }
              })}
            </Grid>
          }
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12} lg={1}></Grid>
        </Hidden>
        <Grid item xs={12} md={4} lg={3}>
          <SummaryPanel bookingInput={values} oneWayRentalFee={props.oneWayRentalFee ? props.oneWayRentalFee : undefined} />
        </Grid>
      </Grid>
    </Container >
  );
};
