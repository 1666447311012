import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0 0.5rem 0 0",
  },
  title: {
    marginBottom: theme.spacing(0.4),
  },
  box: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    borderRadius: 0,
    maxWidth: "400px",
  },
}));

export const TestDriveBookingStepper: React.FC = () => {
  const classes = useStyles();
  const testDriveBookingState = useSelector(
    (state: IAppState) => state.testDriveBookingReducer
  );
  return (
    <Grid container item xs={12} spacing={1}>
      {testDriveBookingState.stepperData.dealership && (
        <Grid item xs={3}>
          <Typography variant="h3" className={classes.title}>
            Dealership
          </Typography>
          <Box className={classes.box}>
            <Typography>
              {testDriveBookingState.stepperData.dealership}
            </Typography>
          </Box>
        </Grid>
      )}
      {testDriveBookingState.stepperData.model && (
        <Grid item xs={3}>
          <Typography variant="h3" className={classes.title}>
            Model
          </Typography>
          <Box className={classes.box}>
            <Typography>
              {testDriveBookingState.stepperData.model}
            </Typography>
          </Box>
        </Grid>
      )}

      {testDriveBookingState.stepperData.slot && (
        <Grid item xs={3}>
          <Typography variant="h3" className={classes.title}>
            Slot
          </Typography>
          <Box className={classes.box}>
            <Typography>
              {testDriveBookingState.stepperData.slot}
            </Typography>
          </Box>
        </Grid>
      )}

      {testDriveBookingState.stepperData.experience && (
        <Grid item xs={3}>
          <Typography variant="h3" className={classes.title}>
            Experience
          </Typography>
          <Box className={classes.box}>
            <Typography>
              {testDriveBookingState.stepperData.experience}
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
