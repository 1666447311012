import { gql } from "apollo-boost";

export const GET_VEHICLE_GROUP_FROM_BRNACHES = gql`
  query getVehicleGroupsFromBranches($appSettingId: String!) {
    getVehicleGroupsFromBranches(appSettingId: $appSettingId) {
        groupName 
        imageUrl
        branches {
            branchId
            vehicleGroupId
        }
    }
  }
`;
