import { useLazyQuery } from "@apollo/client";
import {
  makeStyles,
  Theme,
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { GET_BOOKING } from "../../../../graphql/bookings/getBookingQuery";
import { ICustomer } from "../../../../reducers/bookings/types";
import { IAppState } from "../../../../store";
import { getLocalizedBookingSyntex } from "../../../../utils/localized.syntex";
import { TopBar } from "../../../TopBar/TopBar";
import { ITestDriveBookingDetails } from "../../../../reducers/testDriveBooking/types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: "0 1rem",
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
    marginBottom: theme.spacing(0.5),
  },
  value: {
    fontSize: "0.875rem",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

interface ITestDriveBookingSummary {
  id: string;
  bookingReference: string;
  vehicle: string;
  testDriveBookingDetails: ITestDriveBookingDetails;
  branchName: string;
  branchAddress: string;
  bookingTime: string;
  customer: ICustomer;
}

const TestDriveBookingConfirmation: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [testDriveBookingSummary, setTestDriveBookingSummary] =
    useState<ITestDriveBookingSummary>();
  const organisation = useSelector(
    (state: IAppState) =>
      state.consumerWebsiteReducer.consumerWebsite.organisation
  );
  const branchData = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.branches
  );
  const country = organisation!.address.country;

  const [loadBooking, { loading: bookingDataLoading }] = useLazyQuery(
    GET_BOOKING,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ consumerGetBooking }) => {
        if (
          consumerGetBooking &&
          consumerGetBooking.testDriveBookingDetails &&
          branchData
        ) {
          const selectedBranch = branchData.find(
            (branch) => branch.id === consumerGetBooking.branchId
          );
          setTestDriveBookingSummary({
            id: consumerGetBooking.id,
            vehicle:
              consumerGetBooking.currentBookingSchedules.vehicle.make +
              " " +
              consumerGetBooking.currentBookingSchedules.vehicle.model,
            testDriveBookingDetails: consumerGetBooking.testDriveBookingDetails,
            bookingTime: "",
            bookingReference: consumerGetBooking.referenceNumber,
            branchName: selectedBranch?.name || "N/A",
            branchAddress: selectedBranch?.address.fullAddress || "N/A",
            customer: consumerGetBooking.customer,
          });
        } else {
          history.push("/");
        }
      },
    }
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const bookingId = params.get("id");
    if (bookingId) {
      return loadBooking({
        variables: {
          bookingId: bookingId,
        },
      });
    }
  }, [loadBooking, location]);

  if (!testDriveBookingSummary || bookingDataLoading) {
    return (
      <div
        style={{
          height: 400,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <TopBar height={70} logoSize={44} />
      <Grid item container xs={12} style={{ marginTop: 75 }}>
        <Paper className={classes.root} elevation={0}>
          <Typography variant="h1" className={classes.header}>
            {getLocalizedBookingSyntex(country)} Confirmation
          </Typography>

          <Box className={classes.section}>
            <Typography variant="h3" gutterBottom>
              Your Booking Details
            </Typography>
          </Box>
          <Box className={classes.section}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>
                  {getLocalizedBookingSyntex(country)} Reference
                </Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.bookingReference}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>Model</Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.vehicle}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>Booking Type</Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.testDriveBookingDetails.type}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>Booking Time</Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.bookingTime}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.section}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>Experience</Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.testDriveBookingDetails.experience}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>Dealership</Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.branchName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>
                  Dealership Address
                </Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.branchAddress}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.section}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>Customer</Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.customer.firstName}{" "}
                  {testDriveBookingSummary.customer.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>Phone</Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.customer.phoneNumber.phone}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>Address</Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.customer.address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.label}>Email</Typography>
                <Typography className={classes.value}>
                  {testDriveBookingSummary.customer.email}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </div>
  );
};

export default TestDriveBookingConfirmation;
