import {
  CLEAR_TEST_DRIVE_BOOKING,
  TestDriveBookingAction,
  UPDATE_TEST_DRIVE_BOOKING,
} from "../../actions/testDriveBooking/types";

import { testDriveBookingInitialState } from "./consts";
import { ITestDriveBookingState } from "./types";

export default function testDriveBookingReducer(
  state: ITestDriveBookingState = testDriveBookingInitialState,
  action: TestDriveBookingAction
): ITestDriveBookingState {
  switch (action.type) {
    case UPDATE_TEST_DRIVE_BOOKING:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_TEST_DRIVE_BOOKING:
      return {
        ...state,
        ...testDriveBookingInitialState,
      };
    default:
      return state;
  }
}
