import { Container, Grid } from "@material-ui/core";
import { AvailableTestDriveVehicles } from "./WelcomePage/AvailableVehicleGroups";
import CarouselModule from "../../../CarouselModule/CarouselModule";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { TestDriveBookingSteps } from "../../../../reducers/testDriveBooking/types";
import { BranchSelection } from "./BranchSelection";
import { VehicleSelection } from "./VehicleSelection";
import { VehicleConfirmation } from "./VehicleConfirmation";
import { PersonalDetails } from "./PersonalDetails";

export const NewTestDriveBooking: React.FC = () => {
  const website = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );
  const carousel = website.hasCarousel ? website.carousel : [];

  const testDriveBookingState = useSelector(
    (state: IAppState) => state.testDriveBookingReducer
  );

  const renderTestDriveBookingSteps = () => {
    switch (testDriveBookingState.currentStep) {
      case TestDriveBookingSteps.WELCOME_PAGE:
        return (
          <>
            {website.hasCarousel && carousel.length && (
              <Grid container item xs={12}>
                <CarouselModule />
              </Grid>
            )}
            <Container style={{ marginTop: 50 }}>
              <AvailableTestDriveVehicles />
            </Container>
          </>
        );
      case TestDriveBookingSteps.BRANCH_SELECTION:
        return <BranchSelection />;
      case TestDriveBookingSteps.VEHICLE_SELECTION:
        return <VehicleSelection />;
      case TestDriveBookingSteps.VEHICLE_CONFIRMATION:
        return <VehicleConfirmation />;
      case TestDriveBookingSteps.PERSONAL_DETAILS:
        return <PersonalDetails />;
      case TestDriveBookingSteps.CONFIRMATION:
        return <></>;
      default:
        return (
          <>
            {website.hasCarousel && carousel.length && (
              <Grid container item xs={12}>
                <CarouselModule />
              </Grid>
            )}
            <Container style={{ marginTop: 50 }}>
              <AvailableTestDriveVehicles />
            </Container>
          </>
        );
    }
  };

  return (
    <Container style={{ marginTop: 100 }}>
      {renderTestDriveBookingSteps()}
    </Container>
  );
};
