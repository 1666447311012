import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, Fab } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Storage } from "aws-amplify";
import Image from "../Image";
import { useEffect, useState } from "react";
import { getVehicleTypeIcon } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  imageBox: {
    width: "100%",
    height: "70%",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  proceedIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export const VehicleSelectionCard = ({
  imageUrl = "",
  title = "",
  onClick = () => {},
}) => {
  const classes = useStyles();
  const [image, setImage] = useState<string>(getVehicleTypeIcon("COUPE"));

  const getImage = async (image: string) => {
    const config = { contentType: "image/*", level: "public" };
    const file = await Storage.get(image, config).then((result) => {
      return result;
    });
    const imgResponse = await fetch(file as string).then((res) => {
      return res;
    });
    if (!imgResponse.ok) {
      return;
    }
    setImage(file as string);
  };

  useEffect(() => {
    if (imageUrl) {
      getImage(imageUrl);
    }
  });

  return (
    <Box className={classes.root}>
      <Box className={classes.imageBox} aria-label={title}>
        <Image key={`${title}-1`} imageKey={image} />
      </Box>
      <Grid item xs={12}>
        <Typography variant="h2" component="h2" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Fab
          aria-label="proceed-with-vehicle-selection"
          variant="extended"
          size="medium"
          style={{
            backgroundColor: "var(--brand-accent)",
          }}
          onClick={onClick}
        >
          Select
          <ArrowForwardIcon className={classes.proceedIcon} />
        </Fab>
      </Grid>
    </Box>
  );
};
