import { gql } from "apollo-boost";

export const GET_VEHICLE_AVAILABILITY = gql`
  query getTestDriveVehicleAvailability(
    $queryDate: String!
    $vehicleIds: [String]!
    $branchId: String!
    $appSettingId: String!
  ) {
    getTestDriveVehicleAvailability(
      queryDate: $queryDate
      vehicleIds: $vehicleIds
      branchId: $branchId
      appSettingId: $appSettingId
    ) {
      queryDate
      isExtended
      timeSlots {
        timeSlot
        vehicleId
      }
    }
  }
`;
