import React from "react";
import CarouselModule from '../CarouselModule/CarouselModule'
import GalleryModule from '../VehicleGallery/GalleryModule'
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
import styles from './index.module.css';
import Container from '@material-ui/core/Container';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { BookingType } from "../../reducers/bookings/types";
import { AvailableTestDriveVehicles } from "../views/NewBooking/TestDriveBooking/WelcomePage/AvailableVehicleGroups";

interface IProps {
  setLocation: (locationId: string, branchId: string) => void;
  showCarousel: boolean;
  bookingType: BookingType;
}

const features = [
  {
    label: "Flexible Leasing Options",
    rental: false,
    subscription: true
  },
  {
    label: "Choose the duration of your subscription as you go",
    rental: false,
    subscription: true
  },
  {
    label: "Change car anytime*",
    rental: false,
    subscription: true
  },
  {
    label: "Home delivery option",
    rental: false,
    subscription: true
  },
  {
    label: "100% digital",
    rental: true,
    subscription: true
  },
  {
    label: "MOT and taxes",
    rental: true,
    subscription: true
  },
  {
    label: "Without down payment",
    rental: true,
    subscription: true
  },
  {
    label: "Vehicle Servicing",
    rental: true,
    subscription: true
  },
]

const PageModules: React.FC<IProps> = (props) => {
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const carousel = website.hasCarousel ? website.carousel : []
  const vehiclesGallery = website.hasGallery ? website.gallery : []
  const description = website.websiteDescription || '';
  const htmlDescription = website.htmlDescription || false 
  return (
    <div className={styles.pageModule}>
      {props.showCarousel && description.length ? <div className={styles.moduleWrap}>
        <Container>
          {htmlDescription ? <div style={{ textAlign: "initial" }} dangerouslySetInnerHTML={{ __html: description }}></div> : <h4 style={{ padding: "0 30px", fontWeight: "initial", margin: 0 }}>{description}</h4>}
        </Container>
      </div> : null}
      {props.showCarousel && website.hasCarousel && carousel.length ? <CarouselModule /> : null}
      {/* <LocationModule setLocation={props.setLocation} /> */}
      {props.bookingType === BookingType.RENTAL && website.hasGallery && vehiclesGallery.length ? <GalleryModule /> : null}
      {props.bookingType === BookingType.SUBSCRIPTION && 
        <div className={styles.diffModule}>
          <TableContainer component={Paper} elevation={0}>
            <Table style={{ borderBottom: 0}}>
              <TableHead>
                <TableRow style={{ borderBottom: 0}}>
                  <TableCell style={{ borderBottom: 0,  maxWidth: "20px"}}>
                    <Typography variant="h2">
                      Benefits
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: 0, maxWidth: "10px"}} align="center">
                    <Typography variant="h3">
                      Rental
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: 0, maxWidth: "10px" }} align="center">
                    <Typography variant="h3">
                      Subscription
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {features.map(feat => 
                  <TableRow key={feat.label}>
                    <TableCell style={{ maxWidth: "110px"}} component="th" scope="row">
                      <Typography variant="body1">
                        {feat.label}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {feat.rental ? <CheckIcon /> : <ClearIcon />}
                    </TableCell>
                    <TableCell align="center">
                      {feat.subscription ? <CheckIcon /> : <ClearIcon />}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
      {props.bookingType === BookingType.TEST_DRIVE && 
        <div className={styles.diffModule}>
          <AvailableTestDriveVehicles /> 
        </div>
      }
    </div>
  )
}

export default PageModules