import React from "react";
import styles from "./index.module.css";

interface IProps {
  imageKey: string;
}

const Image: React.FC<IProps> = (props) => {
  return (
    <div className={styles.imageContainer}>
      <div className={styles.image}>
        <img alt="Vehicle" src={props.imageKey} />
      </div>
    </div>
  );
};

export default Image;
