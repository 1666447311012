import { ITestDriveBookingState } from "../../reducers/testDriveBooking/types";
import {
  CLEAR_TEST_DRIVE_BOOKING,
  TestDriveBookingAction,
  UPDATE_TEST_DRIVE_BOOKING,
} from "./types";

export function updateTestDriveBooking(
  payload: ITestDriveBookingState
): TestDriveBookingAction {
  return {
    payload,
    type: UPDATE_TEST_DRIVE_BOOKING,
  };
}

export function clearTestDriveBooking(): TestDriveBookingAction {
  return {
    type: CLEAR_TEST_DRIVE_BOOKING,
  };
}
