import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { updateTestDriveBooking } from "../../../../../actions/testDriveBooking/actions";
import { TestDriveBookingSteps } from "../../../../../reducers/testDriveBooking/types";
import { IAppState } from "../../../../../store";
import { useLazyQuery } from "@apollo/client";
import { ApolloError } from "apollo-boost";
import { GET_VEHICLE_GROUP_FROM_BRNACHES } from "../../../../../graphql/bookings/getVehicleGroupsInBranches";
import { useSnackBar } from "../../../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../SnackbarWrapper/SnackbarWrapper";
import {
  formatGraphQLErrorMessage,
  getVehicleTypeIcon,
} from "../../../../utils";
import { Skeleton } from "@material-ui/lab";
import Image from "../Image";
import { Storage } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 250,
    height: 150,
    position: "relative",
    overflow: "hidden",
    borderRadius: 0,
    "&:hover $overlay": {
      opacity: 1,
    },
    "&:hover $content": {
      opacity: 1,
    },
  },
  media: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    transition: "opacity 0.3s ease",
    opacity: 0,
  },
  content: {
    position: "relative",
    zIndex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    color: theme.palette.common.white,
    textAlign: "center",
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
}));

interface ImageCardProps {
  imageUrl?: string;
  title?: string;
  branches: any[];
  onClick?: any;
}

export default function ImageCard({
  imageUrl = "",
  title = "",
  onClick = () => {},
  branches = [],
}: ImageCardProps) {
  const classes = useStyles();
  const [image, setImage] = useState<string>(getVehicleTypeIcon("COUPE"));

  const nextStep = () => {
    onClick(branches);
  };

  const getImage = async (image: string) => {
    const config = { contentType: "image/*", level: "public" };
    const file = await Storage.get(image, config).then((result) => {
      return result;
    });
    const imgResponse = await fetch(file as string).then((res) => {
      return res;
    });
    if (!imgResponse.ok) {
      return;
    }
    setImage(file as string);
  };

  useEffect(() => {
    if (imageUrl) {
      getImage(imageUrl);
    }
  });

  return (
    <Card className={classes.root} onClick={() => nextStep()}>
      <div className={classes.media} aria-label={title}>
        <Image key={`${title}-1`} imageKey={image} />
      </div>
      <div className={classes.overlay} />
      <CardContent className={classes.content}>
        <Typography variant="h2" component="h2" gutterBottom>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}

export const AvailableTestDriveVehicles: React.FC = () => {
  const dispatch = useDispatch();

  const testDriveBookingState = useSelector(
    (state: IAppState) => state.testDriveBookingReducer
  );
  const appSettings = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );
  const snackbar = useSnackBar();
  const [availableGroups, setAvaialbleGroups] = useState<any>([]);

  const [getVehicleGroupsFromBranches, { loading: groupsLoading }] =
    useLazyQuery(GET_VEHICLE_GROUP_FROM_BRNACHES, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.getVehicleGroupsFromBranches) {
          setAvaialbleGroups(data.getVehicleGroupsFromBranches);
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR,
        });
      },
    });

  useEffect(() => {
    getVehicleGroupsFromBranches({
      variables: {
        appSettingId: appSettings.id,
      },
    });
  }, [appSettings, getVehicleGroupsFromBranches]);

  const nextStep = (branches: any[]) => {
    dispatch(
      updateTestDriveBooking({
        ...testDriveBookingState,
        availableBranches: branches,
        currentStep: TestDriveBookingSteps.BRANCH_SELECTION,
      })
    );
  };

  return (
    <Grid container xs={12} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2" component={"h2"}>
          Available Now
        </Typography>
      </Grid>
      {groupsLoading &&
        new Array(8).fill(1).map(() => (
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Skeleton
              animation="wave"
              variant="rect"
              width={"100%"}
              height={250}
            />
          </Grid>
        ))}
      {availableGroups.map((group: any) => (
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <ImageCard
            title={group.groupName}
            branches={group.branches}
            imageUrl={group.imageUrl}
            onClick={nextStep}
          />
        </Grid>
      ))}
    </Grid>
  );
};
