import { Store, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";

import { IBookingState } from "./reducers/bookings/types";
import { IConsumerWebsiteState } from "./reducers/website/types";
import rootReducer from "./reducers/root.reducer";
import { IBranchState } from "./reducers/branch/types";
import { IAuthState } from "./reducers/auth/types";
import { ITestDriveBookingState } from "./reducers/testDriveBooking/types";

export interface IAction {
  type: string;
}

export interface IAppState {
  bookingReducer: IBookingState;
  consumerWebsiteReducer: IConsumerWebsiteState;
  testDriveBookingReducer: ITestDriveBookingState;
  branchReducer: IBranchState;
  authReducer: IAuthState;
}

// TODO: [NPD-419] Disable composeWithDeveTools in production
const composeEnhancers = composeWithDevTools({});

export default function configureStore(): Store<IAppState, any> {
  const store = createStore(rootReducer, undefined, composeEnhancers());
  return store;
}
