import { gql } from "apollo-boost";

export const GET_GROUPED_VEHICLES_FROM_GROUP = gql`
  query getGroupedVehiclesFromGroup($branchId: String!, $appSettingId: String!, $vehicleGroupId: String!) {
    getGroupedVehiclesFromGroup(branchId: $branchId, appSettingId: $appSettingId, vehicleGroupId: $vehicleGroupId) {
        vehicleName
        imageUrl
        vehicleIds
    }
  }
`;
