import {
  CircularProgress,
  Fab,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { updateTestDriveBooking } from "../../../../actions/testDriveBooking/actions";
import { TestDriveBookingSteps } from "../../../../reducers/testDriveBooking/types";
import { TestDriveBookingStepper } from "./TestDriveStepper";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Formik, Form, Field, FieldProps } from "formik";
import { DateTime as d } from "luxon";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { ApolloError } from "apollo-boost";
import { CREATE_BOOKING } from "../../../../graphql/bookings/createBookingMutation";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../utils";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { useHistory } from "react-router-dom";
import { BookingType } from "../../../../reducers/bookings/types";
import { IPhoneNumber } from "../../../../reducers/auth/types";
import { testDriveBookingInitialState } from "../../../../reducers/testDriveBooking/consts";
import { CREATE_CUSTOMER } from "../../../../graphql/customers/createCustomerMutation";
import { IAddress } from "../../../../reducers/website/types";
import AddressPicker from "../../../AddressPicker/AddressPicker";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { StyledInput } from "../../CustomerPortal/Invoices";
import { getDefaultCountryCode } from "../../../../utils/localized.syntex";
import * as Yup from "yup";

interface IPersonalDetails {
  title: string;
  firstName: string;
  lastName: string;
  address: IAddress;
  emailId: string;
  phoneNumber: IPhoneNumber;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  title: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
    },
  },
  text: { marginTop: theme.spacing(2), marginBottom: theme.spacing(0.5) },
  proceedIcon: {
    marginRight: theme.spacing(1),
  },
  proceedButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    borderRadius: 0,
  },
}));

const initialPersonalDetails: IPersonalDetails = {
  title: "",
  firstName: "",
  lastName: "",
  address: {
    country: "",
  },
  emailId: "",
  phoneNumber: {
    phone: "",
    country: "",
  },
};

const titles = [
  {
    value: "mr",
    label: "Mr.",
  },
  {
    value: "mrs",
    label: "Mrs.",
  },
];

const prepareBookingInput = (values: any, customerId: string) => {
  const pickupDateTime = d
    .fromISO(values.selectedDate)
    .toUTC()
    .startOf("day")
    .plus({ hours: d.fromFormat(values.selectedSlot, "h:mm a").hour });
  const dropoffDateTime = pickupDateTime.plus({ hour: 1 });
  const bookingCreateInput: any = {
    pickupServiceLocation: values.pickupLocation.id,
    pickupDateTime: pickupDateTime.toISO(),
    dropoffDateTime: dropoffDateTime.toISO(),
    dropoffServiceLocation: values.pickupLocation.id,
    customer: customerId,
    vehicleGroups: [
      {
        vehicleGroup: values.vehicleGroupId,
        vehicleIds: [values.vehicleId],
        baseRate: 0,
        deposit: 0,
        mileageLimit: 0,
        unlimitedMileage: false,
        pricePerExtraMile: 0,
        count: 0,
      },
    ],
    insuranceRate: 0,
    excess: 0,
    addonRequirements: [],
    rateTypeName: "",
    rateTypeDuration: 0,
    locationSurcharges: [],
    bookingCategory: BookingType.TEST_DRIVE,
    testDriveBookingDetails: values.testDriveBookingDetails,
  };

  console.log(bookingCreateInput);

  return bookingCreateInput;
};

export const PersonalDetails: React.FC = () => {
  const classes = useStyles();
  const testDriveBookingState = useSelector(
    (state: IAppState) => state.testDriveBookingReducer
  );
  const userState = useSelector((state: IAppState) => state.authReducer.user);

  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const history = useHistory();

  const [personalDetails, setPersonalDetails] = useState<IPersonalDetails>(
    initialPersonalDetails
  );
  const [customerId, setCustomerId] = useState<string>("");
  const website = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );

  const [createCustomer, { loading: creatingCustomer }] = useMutation(
    CREATE_CUSTOMER,
    {
      onCompleted: ({ consumerCreateCustomer }) => {
        const bookingInput = prepareBookingInput(
          testDriveBookingState,
          consumerCreateCustomer.id
        );
        createBookingMutation({
          variables: {
            branchId: testDriveBookingState.branchId,
            booking: bookingInput,
          },
        });
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR,
        });
      },
    }
  );

  useEffect(() => {
    if (userState && userState.id && userState.customerId) {
      setCustomerId(userState.customerId);
      setPersonalDetails({
        ...personalDetails,
        firstName: userState.firstName,
        lastName: userState.lastName,
        emailId: userState.email,
        phoneNumber: userState.phoneNumber,
      });
    } else if (userState && userState.id && userState.businesscustomers) {
      setCustomerId(userState.businesscustomers.id);
      setPersonalDetails({
        ...personalDetails,
        emailId: userState.businesscustomers.billing.email,
        phoneNumber: userState.businesscustomers.billing.phoneNumber,
      });
    }
  }, [userState]);

  const [createBookingMutation, { loading: bookingCreating }] = useMutation(
    CREATE_BOOKING,
    {
      onCompleted: (data) => {
        history.push(`/test-drive-booking?id=${data.consumerCreateBooking.id}`);
        dispatch(
          updateTestDriveBooking({
            ...testDriveBookingInitialState,
          })
        );
        snackbar({
          message: "Test Drive Requested",
          variant: SnackBarVariant.SUCCESS,
        });
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR,
        });
      },
    }
  );

  const nextStep = (values: IPersonalDetails) => {
    const customerDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailId,
      phoneNumber: values.phoneNumber,
      location: values.address,
    };

    if (customerId) {
      const bookingInput = prepareBookingInput(
        testDriveBookingState,
        customerId
      );
      createBookingMutation({
        variables: {
          branchId: testDriveBookingState.branchId,
          booking: bookingInput,
        },
      });
    } else {
      createCustomer({
        variables: {
          customer: customerDetails,
        },
      });
    }
  };

  const prevStep = () => {
    dispatch(
      updateTestDriveBooking({
        ...testDriveBookingState,
        currentStep: TestDriveBookingSteps.VEHICLE_CONFIRMATION,
      })
    );
  };

  const customerInfoSchema = Yup.object().shape({
    emailId: Yup.string()
      .email("Please enter a valid email.")
      .required("Email is required."),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required."),
    }),
    title: Yup.string().required("Title is required."),
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    address: Yup.object()
      .shape({
        fullAddress: Yup.string().required("Address is required"),
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required"),
      })
      .required("Address is required"),
  });

  return (
    <Grid item container spacing={2}>
      <Grid
        item
        container
        xs={12}
        spacing={1}
        alignItems="flex-start"
        justify="flex-start"
      >
        <Grid item>
          <IconButton aria-label="previous-step" onClick={prevStep}>
            <ArrowBackIcon style={{ padding: 0 }} />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h1" component={"h1"}>
            Personal Details
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TestDriveBookingStepper />
      </Grid>
      <Grid container item xs={12}>
        <Formik
          validationSchema={customerInfoSchema}
          initialValues={personalDetails}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            nextStep(values);
            setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <Form className={classes.form}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Typography variant="body1" className={classes.text}>
                      Title
                    </Typography>
                    <Select
                      id={"title-select"}
                      value={formikProps.values.title}
                      name={"title"}
                      fullWidth
                      onChange={formikProps.handleChange}
                      className={classes.select}
                    >
                      {titles.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formikProps.touched.title && (
                      <>
                        <Typography variant="body2" color="error">
                          {formikProps.touched.title &&
                            Boolean(formikProps.errors.title) &&
                            formikProps.errors.title}
                        </Typography>
                      </>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Typography variant="body1" className={classes.text}>
                      First Name
                    </Typography>
                    <TextField
                      id={"first-name-input"}
                      name={"firstName"}
                      required
                      value={formikProps.values.firstName}
                      onChange={formikProps.handleChange}
                      className={classes.select}
                    ></TextField>
                    {formikProps.touched.firstName && (
                      <>
                        <Typography variant="body2" color="error">
                          {formikProps.touched.firstName &&
                            Boolean(formikProps.errors.firstName) &&
                            formikProps.errors.firstName}
                        </Typography>
                      </>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Typography variant="body1" className={classes.text}>
                      Last Name
                    </Typography>
                    <TextField
                      id={"last-name-input"}
                      name={"lastName"}
                      value={formikProps.values.lastName}
                      onChange={formikProps.handleChange}
                      className={classes.select}
                    ></TextField>
                    {formikProps.touched.lastName && (
                      <>
                        <Typography variant="body2" color="error">
                          {formikProps.touched.lastName &&
                            Boolean(formikProps.errors.lastName) &&
                            formikProps.errors.lastName}
                        </Typography>
                      </>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Typography variant="body1" className={classes.text}>
                      Email Address
                    </Typography>
                    <TextField
                      id={"email-input"}
                      value={formikProps.values.emailId}
                      name={"emailId"}
                      onChange={formikProps.handleChange}
                      className={classes.select}
                    ></TextField>
                    {formikProps.touched.title && (
                      <>
                        <Typography variant="body2" color="error">
                          {formikProps.touched.emailId &&
                            Boolean(formikProps.errors.emailId) &&
                            formikProps.errors.emailId}
                        </Typography>
                      </>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Typography variant="body1" className={classes.text}>
                      Address *
                    </Typography>
                    <AddressPicker
                      fieldName={"address"}
                      onChange={(address: IAddress | undefined) => {
                        if (address && address.fullAddress) {
                          formikProps.setFieldValue("address", address);
                        } else {
                          formikProps.setFieldValue("address", {
                            street: "",
                            city: "",
                            country: "",
                            fullAddress: "",
                            state: "",
                            zipcode: "",
                          });
                        }
                      }}
                    />
                    {formikProps.touched.address && (
                      <>
                        <Typography variant="body2" color="error">
                          {formikProps.touched.address.fullAddress &&
                            Boolean(formikProps.errors.address?.fullAddress) &&
                            formikProps.errors.address?.fullAddress}
                        </Typography>
                      </>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Typography variant="body1" className={classes.text}>
                      Mobile Number *
                    </Typography>
                    <Field component={StyledInput} name={"phoneNumber.phone"}>
                      {({ field, meta: { touched, error } }: FieldProps) => {
                        return (
                          <div>
                            <div
                              style={{
                                border: `1px solid ${
                                  touched && Boolean(error)
                                    ? "#D13135"
                                    : "#c0c0c0"
                                }`,
                                borderRadius: 0,
                              }}
                            >
                              <PhoneInput
                                {...field}
                                international={false}
                                defaultCountry={getDefaultCountryCode(
                                  website.organisation.address.country
                                )}
                                placeholder="Enter phone number *"
                                className={
                                  touched && Boolean(error)
                                    ? "PhoneInputInputError"
                                    : ""
                                }
                                name={"phoneNumber.phone"}
                                onChange={(val) => {
                                  formikProps.setFieldValue(
                                    "phoneNumber.phone",
                                    val
                                  );
                                }}
                                onCountryChange={(val) => {
                                  formikProps.setFieldValue(
                                    "phoneNumber.country",
                                    val
                                  );
                                }}
                              />
                            </div>
                            <Typography variant="body2" color="error">
                              {touched && Boolean(error) && error}
                            </Typography>
                          </div>
                        );
                      }}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined">
                    <Grid item xs={12}>
                      <Fab
                        aria-label="request-test-drive"
                        variant="extended"
                        size="medium"
                        type="submit"
                        className={classes.proceedButton}
                        style={{
                          backgroundColor:
                            creatingCustomer || bookingCreating
                              ? "#e0e0e0"
                              : "var(--brand-accent)",
                        }}
                        disabled={creatingCustomer || bookingCreating}
                      >
                        {bookingCreating || creatingCustomer ? (
                          <CircularProgress
                            size={20}
                            className={classes.proceedIcon}
                          />
                        ) : (
                          <ArrowForwardIcon className={classes.proceedIcon} />
                        )}
                        Request Test Drive
                      </Fab>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
